import {FC, useState, useEffect, createContext, useContext, Dispatch, SetStateAction} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'
import {Button, Modal} from 'react-bootstrap'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import OutlinedInput from '@mui/material/OutlinedInput'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import {useDispatch, useSelector} from 'react-redux'
import MenuItem from '@mui/material/MenuItem'


type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
const _currentUser = JSON.parse(localStorage.getItem('_currentUser')!)
const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: _currentUser || undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(_currentUser)
  const [isShown, setIsShown] = useState(false)

  const handleClose = () => {
    setIsShown(false)
  }

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
      setIsShown(true)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }
  const [personName, setPersonName] = useState<string[]>([])


  const Pods = useSelector((state: any) => state.pods.pods_list)


  const handleChange = (event: any) => {
    const {
      target: {value},
    } = event
    const results = Pods.results.filter((d: any) => value.some((v: any) => v === d.pod_name))
    const final_result = results.map((r: any) => r.id)
    setPersonName([...final_result])
  }

  return (
    <>
    
      <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
        {children}
      </AuthContext.Provider>

      <Modal show={isShown} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pod Selection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FormControl sx={{m: 5, width: 500}}>
              <InputLabel id='demo-multiple-checkbox-label'>
                <div className='pod'>Select Pods</div>
              </InputLabel>
              <Select
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                multiple
                value={
                  Pods
                    ? Pods.results
                        .filter((d: any) => personName.some((v: any) => v === d.id))
                        ?.map((r: any) => r.pod_name)
                    : []
                }
                onChange={handleChange}
                input={<OutlinedInput label='Select Pod' />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {Pods
                  ? Pods.results.map((data: any, i: number) => (
                      <MenuItem key={i} value={data.pod_name}>
                        <Checkbox
                          checked={
                            Pods.results
                              .filter((d: any) => personName.some((v: any) => v === d.id))
                              ?.map((r: any) => r.pod_name)
                              .indexOf(data.pod_name) > -1
                          }
                        />
                        <ListItemText primary={data.pod_name} />
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleClose}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(false)

  // const dynammicModalClass = () => (isShown ? { display: 'block' } : '');

  // useEffect(() => {
  //   if (!sessionStorage.popupModal) {
  //     const timer = setTimeout(() => {
  //       setIsShown(true);
  //       sessionStorage.popupModal = 1;
  //     },1000);

  //     return () => clearTimeout(timer);
  //   }
  // }, []);

  useEffect(() => {
    if (auth && auth.api_token) {
      const currentUser = localStorage.getItem('_currentUser')
      if (currentUser !== null) {
        setCurrentUser(JSON.parse(currentUser))
      }
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // if (!sessionStorage.popupModal) {
    //   const timer = setTimeout(() => {
    //     setIsShown(true);
    //     sessionStorage.popupModal = 1;
    //   },1000);

    //   return () => clearTimeout(timer);
    // }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {showSplashScreen ? (
        <LayoutSplashScreen />
      ) : (
        // <div className="modal" id="channelModal">
        //   <div className="modal-dialog modal-dialog-centered" role="document">
        //     <div className="modal-content">
        //       <div className="modal-header">
        //         <h5 className="modal-title text-light">Free Measure. Free Quote.</h5>

        //         <button
        //           onClick={closeModal}
        //           style={{ color: '#fff' }}
        //           type="button"
        //           className="close"
        //           data-dismiss="modal"
        //           aria-label="Close"
        //         >
        //           <span aria-hidden="true">&times;</span>
        //         </button>
        //       </div>

        //       <div className="modal-body">
        //         <div className="row">
        //           <div className="col-6">
        //             <img src="image.jpg" alt="react-hooks" />
        //           </div>

        //           <div className="col-6">
        //             <p className="lead text-light">
        //               Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repudiandae cumque, assumenda cupiditate
        //               incidunt reiciendis earum
        //             </p>
        //           </div>
        //         </div>
        //       </div>

        //       <div className="modal-footer">
        //         <button className="btn-lg btn btn-primary">
        //           <span style={{ color: '#fff' }}>
        //             <i className="fa fa-phone mr-1 " />
        //             <a href="tel:01234567" style={{ color: '#fff' }}>
        //               0123 4567{' '}
        //             </a>
        //           </span>
        //         </button>
        //         <button onClick={closeModal} type="button" className="btn btn-lg">
        //           No Thanks
        //         </button>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        <>{children}</>
      )}
    </>
  )

  //  return isShown ? <h3>Modal content</h3> : null;
  //   return isShown ? (
  //     <div className="modal" style={dynammicModalClass()} id="channelModal">
  //       <div className="modal-dialog modal-dialog-centered" role="document">
  //         <div className="modal-content">
  //           <div className="modal-header">
  //             <h5 className="modal-title text-light">Free Measure. Free Quote.</h5>

  //             <button
  //               onClick={closeModal}
  //               style={{ color: '#fff' }}
  //               type="button"
  //               className="close"
  //               data-dismiss="modal"
  //               aria-label="Close"
  //             >
  //               <span aria-hidden="true">&times;</span>
  //             </button>
  //           </div>

  //           <div className="modal-body">
  //             <div className="row">
  //               <div className="col-6">
  //                 <img src="image.jpg" alt="react-hooks" />
  //               </div>

  //               <div className="col-6">
  //                 <p className="lead text-light">
  //                   Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repudiandae cumque, assumenda cupiditate
  //                   incidunt reiciendis earum
  //                 </p>
  //               </div>
  //             </div>
  //           </div>

  //           <div className="modal-footer">
  //             <button className="btn-lg btn btn-primary">
  //               <span style={{ color: '#fff' }}>
  //                 <i className="fa fa-phone mr-1 " />
  //                 <a href="tel:01234567" style={{ color: '#fff' }}>
  //                   0123 4567{' '}
  //                 </a>
  //               </span>
  //             </button>
  //             <button onClick={closeModal} type="button" className="btn btn-lg">
  //               No Thanks
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   ) : null;

  // const Modal = () => {
  //   const [isShown, setIsShown] = useState(false);

  //   const showModal = () => {
  //     setIsShown(true);
  //   };

  //   const closeModal = () => {
  //     setIsShown(false);
  //   };

  //   const dynammicModalClass = () => (isShown ? { display: 'block' } : '');

  //   useEffect(() => {
  //     if (!sessionStorage.popupModal) {
  //       const timer = setTimeout(() => {
  //         setIsShown(true);
  //         sessionStorage.popupModal = 1;
  //       }, 2000);

  //       return () => clearTimeout(timer);
  //     }
  //   }, []);

  //   // return isShown ? <h3>Modal content</h3> : null;
  //   return isShown ? (
  //     <div className="modal" style={dynammicModalClass()} id="channelModal">
  //       <div className="modal-dialog modal-dialog-centered" role="document">
  //         <div className="modal-content">
  //           <div className="modal-header">
  //             <h5 className="modal-title text-light">Free Measure. Free Quote.</h5>

  //             <button
  //               onClick={closeModal}
  //               style={{ color: '#fff' }}
  //               type="button"
  //               className="close"
  //               data-dismiss="modal"
  //               aria-label="Close"
  //             >
  //               <span aria-hidden="true">&times;</span>
  //             </button>
  //           </div>

  //           <div className="modal-body">
  //             <div className="row">
  //               <div className="col-6">
  //                 <img src="image.jpg" alt="react-hooks" />
  //               </div>

  //               <div className="col-6">
  //                 <p className="lead text-light">
  //                   Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repudiandae cumque, assumenda cupiditate
  //                   incidunt reiciendis earum
  //                 </p>
  //               </div>
  //             </div>
  //           </div>

  //           <div className="modal-footer">
  //             <button className="btn-lg btn btn-primary">
  //               <span style={{ color: '#fff' }}>
  //                 <i className="fa fa-phone mr-1 " />
  //                 <a href="tel:01234567" style={{ color: '#fff' }}>
  //                   0123 4567{' '}
  //                 </a>
  //               </span>
  //             </button>
  //             <button onClick={closeModal} type="button" className="btn btn-lg">
  //               No Thanks
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   ) : null;
  // };
}

export {AuthProvider, AuthInit, useAuth}
