/* eslint-disable jsx-a11y/anchor-is-valid */
import {Box, Button, Drawer, Modal} from '@mui/material'
import moment from 'moment'
import React, {useState} from 'react'
import AlertDetails, {TAlert} from '../alerts/AlertDetails'
import {KTSVG} from '../../../_metronic/helpers'
import {Dropdown1} from '../../../_metronic/partials/content/dropdown/Dropdown1'
import BatteryAlertIcon from '@mui/icons-material/BatteryAlert'
import WatchOffIcon from '@mui/icons-material/WatchOff'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'

type Props = {
  className: string
}
const ListsWidget6: React.FC<Props> = ({className}) => {
  const [visisble, setVisisble] = useState<boolean>(false)
  const [selectedAlert, setSelectedAlert] = useState<TAlert | null>(null)

  const alerts: TAlert[] = [
    {
      id: 5654,
      type: 'Low Heart Rate Alert',
      participant: {
        id: 0,
        first_name: 'Carter',
        last_name: 'Jarrett',
      },
      accepted: false,
      heart_rate: 36,
      alert_time: moment().format(),
    },
    {
      id: 5656,
      type: 'Band Battery Low Alert',
      participant: {
        id: 0,
        first_name: 'Carter',
        last_name: 'Jarrett',
      },
      note: 'Replaced band with new one',
      accepted: true,
      accepted_time: moment().subtract(30, 'minutes').format(),
      note_time: moment().subtract(20, 'minutes').format(),
      heart_rate: 78,
      battery_level: 5,
      alert_time: moment().subtract(35, 'minutes').format(),
      accepted_by: {
        first_name: 'James',
        last_name: 'Henderson',
      },
      worked_by: {
        first_name: 'James',
        last_name: 'Henderson',
      },
    },
    {
      id: 253,
      type: 'Band Tamper Alert',
      participant: {
        id: 0,
        first_name: 'Tyrion',
        last_name: 'Lanister',
      },
      accepted: false,
      heart_rate: 60,
      battery_level: 75,
      alert_time: moment().subtract(200, 'minutes').format(),
    },
    {
      id: 254,
      type: 'Band Battery Low Alert',
      participant: {
        id: 0,
        first_name: 'Jon',
        last_name: 'Snow',
      },
      accepted: false,
      heart_rate: 60,
      battery_level: 5,
      alert_time: moment().subtract(75, 'minutes').format(),
    },
    {
      id: 255,
      type: 'Low Heart Rate Alert',
      participant: {
        id: 0,
        first_name: 'Ragnar',
        last_name: 'Lothbrok',
      },
      note: 'Inmate was sleeping. Other vitals look normal',
      accepted: true,
      accepted_time: moment().subtract(45, 'minutes').format(),
      note_time: moment().subtract(65, 'minutes').format(),
      heart_rate: 38,
      battery_level: 75,
      alert_time: moment().subtract(85, 'minutes').format(),
      accepted_by: {
        first_name: 'Stephanie',
        last_name: 'Wilks',
      },
      worked_by: {
        first_name: 'Stephanie',
        last_name: 'Wilks',
      },
    },
    {
      id: 256,
      type: 'Low Heart Rate Alert',
      participant: {
        id: 0,
        first_name: 'Torvi',
        last_name: 'Harst',
      },
      note: 'Inmate moved to medical ward for observation. Heart rate was abnormally low.',
      accepted: false,
      accepted_time: moment().subtract(80, 'minutes').format(),
      note_time: moment().subtract(85, 'minutes').format(),
      heart_rate: 60,
      battery_level: 75,
      alert_time: moment().subtract(90, 'minutes').format(),
      accepted_by: {
        first_name: 'Tyler',
        last_name: 'Green',
      },
      worked_by: {
        first_name: 'Stephanie',
        last_name: 'Wilks',
      },
    },
  ]

  const getIcon = (type: string) => {
    switch (type) {
      case 'Band Battery Low Alert':
        return <BatteryAlertIcon className='svg-icon-1 ' />
      case 'Low Heart Rate Alert':
        return <MonitorHeartIcon className='svg-icon-1 ' />
      case 'Band Tamper Alert':
        return <WatchOffIcon className='svg-icon-1 ' />
      default:
        return null
    }
  }
  return (
    <>
      <div className='card card-xl-stretch mb-5 mb-xl-8'>
        {/* begin::Header */}
        <div className='card-header border-0'>
          <h3 className='card-title text-dark font-16'>Alerts</h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
            </button>
            <Dropdown1 />
            {/* end::Menu */}
          </div>
        </div>

        <div className='card-body pt-0' style={{overflow: 'scroll', maxHeight: '65vh'}}>
          {alerts.map((v) => {
            return (
              <div
                className='d-flex align-items-center rounded p-5 mb-7'
                style={{background: 'black', cursor: 'pointer'}}
                onClick={() => {
                  setVisisble(true)
                  setSelectedAlert(v)
                }}
              >
                {/* begin::Icon */}
                <span className='svg-icon svg-icon-warning me-5'>{getIcon(v.type)}</span>
                {/* end::Icon */}
                {/* begin::Title */}
                <div className='flex-grow-1 me-2'>
                  <a className='text-gray-800 text-hover-primary font-14'>{v.type}</a>
                  {/* <span className='text-muted fw-semibold d-block'>Due in 2 Days</span> */}
                </div>
                <div className='flex-grow-1 me-2 text-end'>
                  <a className='text-gray-800 text-hover-primary font-14'>
                    {v.participant.first_name} {v.participant.last_name}
                  </a>
                  {/* <span className='text-muted fw-semibold d-block'>Due in 2 Days</span> */}
                </div>
                {/* end::Title */}
                {/* begin::Lable */}
                {/* <span className='fw-bold text-warning py-1'>+28%</span> */}
                {/* end::Lable */}
              </div>
            )
          })}
        </div>
      </div>
      <AlertDetails alert={selectedAlert} visible={visisble} onClose={() => setVisisble(false)} />
    </>
  )
}

export {ListsWidget6}
