import {  createSlice } from "@reduxjs/toolkit";
import { _getPods } from "../actions/pod";

interface initialState {
  list: any;
}

const initialState: initialState = {
 list: {}
}

const pod = createSlice({
    name: 'pod',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(_getPods.fulfilled, (state, action) => ({
          ...state,
          list: action.payload
        })
      ).addCase(_getPods.rejected, (state, action) => ({
          ...state,
          list: action.error
        })
      )
    }
})

export default pod.reducer;

