import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { _getPods } from '../actions/pods'


const podsSlice = createSlice({
  name: 'pods',
  initialState: {
    isLoading: false,
    pods_list: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(_getPods.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(_getPods.fulfilled, (state, action) => {
      state.isLoading = false
      state.pods_list = action.payload
    })
    builder.addCase(_getPods.rejected, (state, action) => {
      console.log('Error', action.payload)
      state.isError = true
    })
  },
  reducers: { },
})

export default podsSlice.reducer
