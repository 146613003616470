/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useState} from 'react'

import Select, {SelectChangeEvent} from '@mui/material/Select'
import {red} from '@mui/material/colors'
import {useDispatch, useSelector} from 'react-redux'
import {_getParticipants} from '../../../state/actions/participant'
import {Switch} from 'devextreme-react'
import {Link} from 'react-router-dom'

type Props = {
  className: string
}

// export default function TablesWidget10(){}

// export default function TablesWidget10(){}

const TablesWidget10: React.FC<Props> = ({className}) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [tables, setTableswidgets10] = useState<any>()

  const [pod, setPod] = useState()

  const dispatch = useDispatch()
  const participants = useSelector((state: any) => state.participants.participants_list)
  const {isLoading} = useSelector((state: any) => state.participants)

  // const [allParticipants, setAllParticipants]= useState(participants.results);

  useEffect(() => {
    dispatch(_getParticipants())
  }, [dispatch])

  // console.log(participants)

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const ITEM_COLOUR = red
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        colour: red,
      },
    },
  }

  if (participants === null) return null

  return (
    <>
      {isLoading ? (
        <div> Loading... </div>
      ) : (
        <div className={`card ${className}`}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label font-16 mb-1'>Inmates</span>
              <span className='text-muted mt-1 fw-semibold fs-7 text-gray-600'>
                {participants?.count > participants.results.length
                  ? `Over ${participants.results.length}`
                  : participants?.count}{' '}
                Inmates
              </span>
            </h3>
            {/* <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a participant'
        >
          
          <a
            href='#'
            className='btn btn-sm btn-light-primary text-white'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            New Participant
          </a>
        </div> */}
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive' style={{overflow: 'scroll', maxHeight: '65vh'}}>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-120px text-gray-700'>Inmate</th>
                    <th className='min-w-120px text-gray-700'>Location</th>
                    <th className='min-w-160px text-gray-700'>Heart Rate</th>
                    <th className='min-w-120px text-gray-700'>Battery Level</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {participants &&
                    participants.results.length > 0 &&
                    participants.results.map((v: any) => {
                      return (
                        <tr>
                          <td>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <Link
                                  to='/profile/overview'
                                  state={{id: v.id}}
                                  className='text-light-white text-hover-primary fs-6'
                                  style={{textTransform: 'capitalize', wordBreak: 'break-all'}}
                                >
                                  {v.first_name} {v.last_name}
                                </Link>
                                {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          HTML, JS, ReactJS
                        </span> */}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='d-flex flex-column w-100 me-2'>
                              <div className='d-flex flex-stack mb-2'>
                                <span className='text-light-white me-2 fs-7 fw-semibold'>
                                  {v.pod_name} - Cell {v.cell_number}
                                </span>
                              </div>
                            </div>
                            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      Web, UI/UX Design
                    </span> */}
                          </td>
                          {v.participantTrackingActivity[0] ? (
                            <>
                              <td className='text-end'>
                                <div className='d-flex flex-column w-100 me-2'>
                                  <div className='d-flex flex-stack mb-2'>
                                    <span className='text-light-white me-2 fs-7 fw-semibold'>
                                      {v.participantTrackingActivity[0]
                                        ? `${v.participantTrackingActivity[0]?.heart_rate} BPM`
                                        : 'No Recorded Heart Rate'}
                                    </span>
                                  </div>
                                  <div className='progress h-6px w-100'>
                                    {v.participantTrackingActivity[0]?.heart_rate > 40 ? (
                                      <div
                                        className='progress-bar'
                                        role='progressbar'
                                        style={{
                                          width: `${
                                            (v.participantTrackingActivity[0]?.heart_rate / 200) *
                                            100
                                          }%`,background: 'green',
                                        }}
                                      ></div>
                                    ) : (
                                      <div
                                        className='progress-bar'
                                        role='progressbar'
                                        style={{
                                          width: `${
                                            (v.participantTrackingActivity[0]?.heart_rate / 200) *
                                            100
                                          }%`, background: 'red',
                                        }}
                                      ></div>
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td style={{alignItems: 'center'}}>
                                <span
                                  className='text-light-white me-2 fs-7 fw-semibold'
                                  style={{marginLeft: 20}}
                                >
                                  {v.participantTrackingActivity[0]
                                    ? `${v.participantTrackingActivity[0]?.battery_info} %`
                                    : 'No Recorded Battery Level'}
                                </span>{' '}
                              </td>{' '}
                            </>
                          ) : (
                            <>
                              {' '}
                              <td className='text-end'>
                                <div className='d-flex flex-column w-100 me-2'>
                                  <div className='d-flex flex-stack mb-2'>
                                    <span className='text-light-white me-2 fs-7 fw-semibold'>
                                      No Recorded Heart Rate
                                    </span>
                                  </div>
                                </div>
                              </td>{' '}
                              <td style={{alignItems: 'center'}}>
                                <span
                                  className='text-light-white me-2 fs-7 fw-semibold'
                                  style={{marginLeft: 20}}
                                >
                                  No Recorded Battery Level
                                </span>{' '}
                              </td>
                            </>
                          )}

                          {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      Web, UI/UX Design
                    </span> */}
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
          {/* begin::Body */}
        </div>
      )}
    </>
  )
}

export {TablesWidget10}
