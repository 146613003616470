import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../helpers/request";

interface ListType {
  count: string
  limit: number
  page: number
  results: any
}

export const _getCells = createAsyncThunk('cell/_list', async () => {
  const response: ListType = await request({ url: "cells", method: 'get' });
  return response;
});
