import axios, { AxiosRequestConfig } from 'axios';
import { removeAuth } from '../app/modules/auth';

const aX = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const request = async (config: AxiosRequestConfig<any>) => {
  try {
    const token: string | null = localStorage.getItem('_tappToken');
    
    if (token !== null) {
      const parsedToken = JSON.parse(token)
      config.headers = {}
      config.headers.Authorization = `Bearer ${parsedToken.api_token}`;
    }

    const { data } = await aX(config);
    return data;
  } catch (e: any) {
    const { message, response } = e;
    if (response) {
      const { data, status } = response;
      if(status === 401) {
        removeAuth()
      }
      
      return data;  
    }

    return {  msg: process.env.NODE_ENV === 'production' ? "Oops! Something went wrong." : message  };
  }
};

export { request };
