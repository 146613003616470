import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { _getDeviceHistoryOnId } from '../actions/participant'

const deviceHistorySlice = createSlice({
  name: 'deviceHistory',
  initialState: {
    isLoading: false,
    deviceHistory_list: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(_getDeviceHistoryOnId.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(_getDeviceHistoryOnId.fulfilled, (state, action) => {
      state.isLoading = false
      state.deviceHistory_list = action.payload
    })
    builder.addCase(_getDeviceHistoryOnId.rejected, (state, action) => {
      console.log('Error', action.payload)
      state.isError = true
    })
  },
  reducers: {
    // todoSuccess: (state, action)=> {
    //     state.data = action.payload;
    //     state.isLoading= false;
    // }
  },
})

export default deviceHistorySlice.reducer
