import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { _getSupervisors } from '../actions/supervisor'


const supervisorsSlice = createSlice({
  name: 'supervisors',
  initialState: {
    isLoading: false,
    supervisors_list: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(_getSupervisors.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(_getSupervisors.fulfilled, (state, action) => {
      state.isLoading = false
      state.supervisors_list = action.payload
    })
    builder.addCase(_getSupervisors.rejected, (state, action) => {
      console.log('Error', action.payload)
      state.isError = true
    })
  },
  reducers: { },
})

export default supervisorsSlice.reducer
