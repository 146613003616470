import {  createSlice } from "@reduxjs/toolkit";
import { _login } from "../actions/user";

interface initialState {
  login: object;
}

const initialState: initialState = {
 login: {}
}

const user = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(_login.fulfilled, (state, action) => ({
          ...state,
          login: action.payload
        })
      ).addCase(_login.rejected, (state, action) => ({
          ...state,
          login: action.error
        })
      )
    }
})

export default user.reducer;

