/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import { Button, Modal } from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import { useAuth } from '../../modules/auth'
import { ListsWidget6 } from './ListsWidget6'
import { TablesWidget10 } from './TablesWidget10'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
    <div className='col-xxl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>

    </div>
    {/* end::Row */}

  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const [isShown, setIsShown] = useState(false);


  // useEffect(()=> {
  //  if(auth && auth.api_token) {
  //   setVerify(true)
  //  }
  //  if(verify) {
  //   setIsShown(true)
  //  }
  // }, [])

  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
