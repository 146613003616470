import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../helpers/request";


interface TrackingActivity {
  heart_rate?: number
  battery_info?: number
  updated_at?: string
}

interface ProfileType {
  id?: number
  first_name?: string
  last_name?: string
  gender?: string
  birth_date?: string
  pod_name?: string
  cell_number?: string
  exit_date?: string
  profile_url?: string
  participantTrackingActivity?: TrackingActivity[]
}

interface ListType {
  count: string
  limit: number
  page: number
  results: []
}

export interface ParticipantType {
  id?: number
  first_name?: string
  last_name?: string
  birth_date?: string
  gender?: string
  height_ft?: string
  height_inch?: string,
  weight?: string
  facility_id?: string
  cell_id?: string
  entry_date?: any
  exit_date?: any
  isEdit?: boolean
}

export const _getInfo = createAsyncThunk('participant/_info', async (id:  number) => {
  const response = await request({ url: `participant/${id}?include=deviceHistory,participantTrackingActivity,pod,cell`, method: 'get' });
  return response;
});

export const _getNotifications = createAsyncThunk('participant/_getNotifications', async (id: number) => {
  const response: ListType = await request({ url: `participant/${id}/notifications`, method: 'get' });
  return response;
});

export const _getTrackingActivity = createAsyncThunk('participant/_trackingActivity', async () => {
  const response: ListType = await request({ url: `participant-tracking-activity`, method: 'get' });
  return response;
});

export const _getDeviceHistory = createAsyncThunk('participant/_deviceHistory', async () => {
  const response: ListType = await request({ url: `device-history`, method: 'get' });
  return response;
});

export const _getParticipants: any = createAsyncThunk('_getParticipants', async () => {
  const response = await request({ url: `participant?include=participantTrackingActivity,cell,pod,`, method: 'get' });
  return response;
});

export const _getDevices: any = createAsyncThunk('_getDevices', async () => {
  const response = await request({ url: `device?limit=200`, method: 'get' });
  return response;
});

export const _saveParticipant = createAsyncThunk('participant/_create', async (data: ParticipantType) => {
  const response = await request({ url: data.isEdit ?`participant/${data.id}` : "participant", data, method: data.isEdit ? 'put' : "post" });
  return response;
});

export const _getDeviceHistoryOnId: any = createAsyncThunk('_getDeviceHistoryOnId', async (id: number) => {
  const response = await request({ url: `device/${64}?include=deviceHistory,facility,participant,uninstalled_by,installed_by`, method: 'get' });
  return response;
});