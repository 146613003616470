import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { _getDevices } from '../actions/participant'

// Action
// export const fetchdevices: any = createAsyncThunk('fetchdevices', async ( ) => {

//   const response = await fetch("https://itwapidev.talitrix.com/api/v1/device", {
//     method: 'GET',
//     headers: {Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImlkIjo2LCJlbWFpbCI6InN1cGVyYWRtaW5AdGFsaXRyaXguY29tIiwiaXNfYWRtaW4iOnRydWUsInRva2VuRXhwaXJ5IjoiMjAyMy0wMy0wMVQxNDoxMTozOS43MTNaIn0sImlhdCI6MTY3NzY2OTA5OSwiZXhwIjoxNjc3Njc5ODk5fQ.6FHmzXABCKn2c1wS9zdq_5uZfVtJw47AmyNDc5ppQ90'}
//   })
//   return response.json()
// })

const devicesSlice = createSlice({
  name: 'devices',
  initialState: {
    isLoading: true,
    devices_list: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(_getDevices.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(_getDevices.fulfilled, (state, action) => {
      state.isLoading = false
      state.devices_list = action.payload
    })
    builder.addCase(_getDevices.rejected, (state, action) => {
      console.log('Error', action.payload)
      state.isError = true
    })
  },
  reducers: {
    // todoSuccess: (state, action)=> {
    //     state.data = action.payload;
    //     state.isLoading= false;
    // }
  },
})

export default devicesSlice.reducer
