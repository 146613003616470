import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { _getParticipants } from '../actions/participant'

// Action
// export const fetchparticipants: any = createAsyncThunk('fetchparticipants', async ( ) => {
 
//   const response = await fetch('https://itwapidev.talitrix.com/api/v1/participant', {
//     method: 'GET',
//     headers: {Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImlkIjo2LCJlbWFpbCI6InN1cGVyYWRtaW5AdGFsaXRyaXguY29tIiwiaXNfYWRtaW4iOnRydWUsInRva2VuRXhwaXJ5IjoiMjAyMy0wMy0wMVQxNDoxMTozOS43MTNaIn0sImlhdCI6MTY3NzY2OTA5OSwiZXhwIjoxNjc3Njc5ODk5fQ.6FHmzXABCKn2c1wS9zdq_5uZfVtJw47AmyNDc5ppQ90'}
//   })
//   return response.json()
// })

const participantsSlice = createSlice({
  name: 'participants',
  initialState: {
    isLoading: true,
    participants_list: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(_getParticipants.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(_getParticipants.fulfilled, (state, action) => {
      state.isLoading = false
      state.participants_list = action.payload
    })
    builder.addCase(_getParticipants.rejected, (state, action) => {
      console.log('Error', action.payload)
      state.isError = true
    })
  },
  reducers: { },
})

export default participantsSlice.reducer
