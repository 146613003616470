/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import Dropdown from 'react-bootstrap/Dropdown'
import {useState} from 'react'
import {useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import {ColorObject} from '@mui/material'
import {red} from '@mui/material/colors'
import React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import {request} from '../../../../helpers/request'
import moment from 'moment'
import InlinedInput from '@mui/material/OutlinedInput'
import './headercss.css'
import {Form} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {_getPods} from '../../../../state/actions/pods'

const color = red[500]
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [tables, setTableswidgets10] = useState<any>()
  // const [pods, setPods] = useState<any>([])
  const [personName, setPersonName] = useState<string[]>([])

  // const [pod, setPod] = useState()
  // useEffect(() => {
  //   async function getMenu() {
  //     const results = await fetch('https://jsonplaceholder.typicode.com/todos')

  //     const data = await results.json()
  //     console.log(data)
  //     console.log('name')
  //     setPod(data.data)
  //   }
  //   getMenu()
  // }, [])

  const dispatch = useDispatch()

  const Pods = useSelector((state: any) => state.pods.pods_list)

  useEffect(() => {
    dispatch(_getPods())
  }, [])

  const handleChange = (event: any) => {
    const {
      target: {value},
    } = event
    const results = Pods.results.filter((d: any) => value.some((v: any) => v === d.pod_name))
    const final_result = results.map((r: any) => r.id)
    setPersonName([...final_result])
  }

  var supervisor_id_temp: any = localStorage.getItem('_currentUser')
  if (supervisor_id_temp != null) {
    var retrievedObject = JSON.parse(supervisor_id_temp)
    console.log(retrievedObject.id, 'supervisor_id')
  }
  var date = moment().utcOffset('+05:30').format('DD-MM-YYYY')

  // .format('YYYY-MM-DD hh:mm:ss a');
  console.log(date, 'moment')

  //  async onSubmit()=>{
  //    const response = await request({ url: `supervisor-pod`, method: 'post' });
  //     return response;
  //      };

  const onSubmit = async (e: any) => {
    e.preventDefault()
    const post: any = {supervisor_id: retrievedObject.id, pod_ids: personName, assigned_date: date}
    try {
      const res: any = await request({url: `supervisor-pod`, method: 'post', data: post})
      console.log(res.data)
      if (res.data) {
        handleClose()
        setPersonName([])
      }
    } catch (e) {
      alert(e)
    }
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.last_name}
            </div>
            <a
              href={`mailto:${currentUser?.email}`}
              className='fw-bold text-muted text-hover-primary fs-7'
            >
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={handleShow} className='menu-link px-5'>
          Pod Selection
        </a>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Pod Selection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FormControl sx={{m: 5, width: 500}}>
              <InputLabel id='demo-multiple-checkbox-label'>
                <div className='pod'>Select Pods</div>
              </InputLabel>
              <Select
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                multiple
                value={
                  Pods
                    ? Pods.results
                        .filter((d: any) => personName.some((v: any) => v === d.id))
                        ?.map((r: any) => r.pod_name)
                    : []
                }
                onChange={handleChange}
                input={<OutlinedInput label='Select Pod' />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {Pods
                  ? Pods.results.map((data: any, i: number) => (
                      <MenuItem key={i} value={data.pod_name}>
                        <Checkbox
                          checked={
                            Pods.results
                              .filter((d: any) => personName.some((v: any) => v === d.id))
                              ?.map((r: any) => r.pod_name)
                              .indexOf(data.pod_name) > -1
                          }
                        />
                        <ListItemText primary={data.pod_name} />
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={onSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
