import Button from 'react-bootstrap/Button'
import moment from 'moment'
import React, {useRef, useState, FC, useEffect} from 'react'
import {Offcanvas, OffcanvasBody, OffcanvasHeader, OffcanvasTitle} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import TextField from '@mui/material/TextField'

export type TAlert = {
  id: number
  type: string
  participant: {
    id: number
    first_name: string
    last_name: string
  }
  note?: string
  accepted: boolean
  accepted_time?: string
  note_time?: string
  heart_rate?: number
  battery_level?: number
  alert_time: string
  accepted_by?: {
    first_name: string
    last_name: string
  }
  worked_by?: {
    first_name: string
    last_name: string
  }
}
interface IProps {
  alert: TAlert | null
  visible: boolean
  onClose: () => void
}
const AlertDetails: FC<IProps> = ({alert, visible, onClose}) => {
  const [accepted, setAccepted] = useState<boolean>(alert?.accepted ? alert?.accepted : false)
  const handleAccept = () => {
    console.log('Accepted at', moment().format('lll'))
    accepted ? onClose() : setAccepted(true)
  }
  return (
    <Offcanvas
      show={visible && alert !== null}
      onHide={onClose}
      placement={'end'}
      style={{width: '30vw'}}
    >
      <OffcanvasHeader>
        <OffcanvasTitle>Alert Details</OffcanvasTitle>
      </OffcanvasHeader>
      <OffcanvasBody>
        {alert !== null && (
          <>
            <div>
              <p className='text-gray-800 font-14'>Alert {alert.id}</p>
            </div>
            <div>
              <p className='text-gray-800 font-14'>Type: {alert.type}</p>
            </div>
            <div>
              <p className='text-gray-800 font-14'>
                Inmate: {alert.participant.first_name} {alert.participant.last_name}
              </p>
            </div>
            <div>
              <p className='text-gray-800 font-14'>
                Time: {moment(alert.alert_time).format('lll')}
              </p>
            </div>
            {alert.heart_rate && (
              <div>
                <p className='text-gray-800 font-14'>Heart Rate: {alert.heart_rate} BPM</p>
              </div>
            )}
            {alert.battery_level && (
              <div>
                <p className='text-gray-800 font-14'>Battery Level: {alert.battery_level} %</p>
              </div>
            )}
            {accepted && (
              <>
                <hr className='horizontallines'></hr>
                <div className='text-gray-800 font-14'>
                  Accepted by: {alert.accepted_by?.first_name} {alert.accepted_by?.last_name} at{' '}
                  {moment(alert.accepted_time).format('lll')}
                </div>{' '}
                {alert.note ? (
                  <div>
                    <div>
                      <p style={{marginTop: 10}} className='text-gray-800 font-14'>
                        Action: {alert.note}
                      </p>
                    </div>
                    <div className='text-gray-800 font-14' style={{marginTop: 10}}>
                      Action Taken By: {alert.worked_by?.first_name} {alert.worked_by?.last_name} at{' '}
                      {moment(alert.note_time).format('lll')}
                    </div>
                  </div>
                ) : (
                  <>
                    <div style={{marginTop: 10}}>
                      <TextField
                        id='outlined-multiline-flexible'
                        label='Action'
                        multiline
                        maxRows={10}
                        fullWidth
                        style={{borderColor: 'orange'}}
                      />
                    </div>
                  </>
                )}
              </>
            )}
            <hr className='horizontallines'></hr>

            <div style={{marginTop: 20}}>
              <Button style={{marginRight: 20}} variant='secondary' onClick={onClose}>
                Close
              </Button>
              {alert.note ? (
                <></>
              ) : (
                <Button style={{alignSelf: 'right'}} variant='primary' onClick={handleAccept}>
                  {accepted ? 'Save' : 'Accept'}
                </Button>
              )}
            </div>
          </>
        )}
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default AlertDetails
