import {  createSlice } from "@reduxjs/toolkit";
import { _getCells } from "../actions/cell";

interface initialState {
  list: any;
}

const initialState: initialState = {
 list: {}
}

const cell = createSlice({
    name: 'cell',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(_getCells.fulfilled, (state, action) => ({
          ...state,
          list: action.payload
        })
      ).addCase(_getCells.rejected, (state, action) => ({
          ...state,
          list: action.error
        })
      )
    }
})

export default cell.reducer;

