import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../helpers/request";

interface loginState {
    email: string;
    password: string;
}

export const _login = createAsyncThunk('user/_login', async (data: loginState) => {
  const response = await request({ url: 'token', method: 'post', data });
  return response;
});