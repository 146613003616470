import { ActionType } from "../action-types/index";
import { Dispatch } from "redux";
import {Action, MenuItem} from '../actions/AsideMenuActions';

export const AsideMenuchange = (item: Array<MenuItem>) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.CHANGE,
            payload: item
        })
    }
}