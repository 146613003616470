/* eslint-disable react/jsx-no-target-blank */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle'
import { useAppSelector } from '../../../../state/hooks'
import { MenuItem } from '../../../../state/actions/AsideMenuActions'
export interface AsideMenuItemWithSubList {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

type props = {
  AsideMenuItemsWithSub: Array<AsideMenuItemWithSubList>
}

const AsideMenuMain: FC = (props) => {
  const intl = useIntl()
  const asideMenuItemList = useAppSelector((state) => state.AsideMenu)

  return (
    <>
      {asideMenuItemList.map((value: MenuItem) => 
        <AsideMenuItem
        to = {value.to}
        icon= {value.icon}
        title={intl.formatMessage({id: value.title})}
        fontIcon= {value.fontIcon}
      />
      )
      }
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}

      {/* <AsideMenuItemWithSub
        to='/pages/reception'
        title='Programs'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Attendance' hasBullet={true} />
        <AsideMenuItem
          to='/pages/programreferral/ProgramReferral'
          title='Referral'
          hasBullet={true}
        />
        <AsideMenuItem
          to='/crafted/account/settings'
          title='Program Participation'
          hasBullet={true}
        />
        <AsideMenuItem to='/pages/jobs/jobs' title='Jobs' hasBullet={true} />
      </AsideMenuItemWithSub> */}
    </>
  )
}

export {AsideMenuMain}
