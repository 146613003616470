import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { _getCells } from '../actions/cells'


const cellsSlice = createSlice({
  name: 'cells',
  initialState: {
    isLoading: false,
    cells_list: null,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(_getCells.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(_getCells.fulfilled, (state, action) => {
      state.isLoading = false
      state.cells_list = action.payload
    })
    builder.addCase(_getCells.rejected, (state, action) => {
      console.log('Error', action.payload)
      state.isError = true
    })
  },
  reducers: { },
})

export default cellsSlice.reducer