import {  createSlice } from "@reduxjs/toolkit";
import { _getDeviceHistory, _getInfo, _getNotifications, _getTrackingActivity, _saveParticipant } from "../actions/participant";

interface initialState {
  info: object
  notifications: any
  trackingActivity:  any
  deviceHistory: any
  save: any
}

const initialState: initialState = {
 info: {},
 notifications: {},
 trackingActivity: {},
 deviceHistory: {},
 save: {}
}

const participant = createSlice({
    name: 'participant',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(_getInfo.fulfilled, (state, action) => ({
          ...state,
          info: action.payload
        })
      ).addCase(_getInfo.rejected, (state, action) => ({
          ...state,
          info: action.error
        })
      ).addCase(_getTrackingActivity.fulfilled, (state, action) => ({
          ...state,
          trackingActivity: action.payload
        })
      ).addCase(_getTrackingActivity.rejected, (state, action) => ({
          ...state,
          trackingActivity: action.error
        })
      ).addCase(_getDeviceHistory.fulfilled, (state, action) => ({
          ...state,
          deviceHistory: action.payload
        })
      ).addCase(_getDeviceHistory.rejected, (state, action) => ({
          ...state,
          deviceHistory: action.error
        })
      ).addCase(_getNotifications.fulfilled, (state, action) => ({
          ...state,
          notifications: action.payload
        })
      ).addCase(_getNotifications.rejected, (state, action) => ({
          ...state,
          notifications: action.error
        })
      ).addCase(_saveParticipant.fulfilled, (state, action) => ({
          ...state,
          save: action.payload
        })
      ).addCase(_saveParticipant.rejected, (state, action) => ({
          ...state,
          save: action.error
        })
      )
    }
})

export default participant.reducer;

