import {Action, MenuItem} from '../actions/AsideMenuActions'
import {ActionType} from '../action-types/index'

const initialState: Array<MenuItem> = [
  {
    to: '/dashboard',
    title: 'MENU.DASHBOARD',
    icon: '/media/icons/talitrix/dashboard.svg',
    fontIcon: 'bi-app-indicator',
  },
  {
    to: '/pages/offender/Offender',
    title: 'Inmates',
    icon: '/media/icons/talitrix/offender.svg',
    fontIcon: 'bi-app-indicator',
  },
  {
    to: '/pages/devices/Devices',
    title: 'MENU.DEVICES',
    icon: '/media/icons/talitrix/device.svg',
    fontIcon: 'bi-app-indicator',
  },
  {
    to: '/pages/alerts/Alerts',
    title: 'Alerts',
    icon: '/media/icons/talitrix/alerts.svg',
    fontIcon: 'bi-app-indicator',
  },
  {
    to: '/pages/supervisors/Supervisors',
    title: 'Supervisors',
    icon: '/media/icons/talitrix/folder.svg',
    fontIcon: 'bi-app-indicator',
  },
  {    to: '/pages/facility/Facility',
    title: 'Facility Management',
    icon: '/media/icons/talitrix/facility.svg',
    fontIcon: 'bi-app-indicator',
  },

]

const AsideMenuReducer = (state: Array<MenuItem> = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.CHANGE:
      return action.payload
    default:
      return state
  }
}

export default AsideMenuReducer
