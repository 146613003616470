import { combineReducers } from "redux";
import AsideMenuReducer from "./AsideMenuReducer";
import user from "./user";
import participant from "./participant";
import pod from "./pod";
import cell from "./cell";
import participantsSlice from './participants';
import devicesSlice from './devices';
import supervisorsSlice from './supervisors';
import podsSlice from './pods'
import cellsSlice from './cells'
import deviceHistorySlice from "./deviceHistory";

const reducers = combineReducers({
    AsideMenu: AsideMenuReducer,
    user,
    participant,
    pod,
    cell,
    participants: participantsSlice,
    devices: devicesSlice,
    pods: podsSlice,
    supervisors: supervisorsSlice,
    cells: cellsSlice,
    deviceHistory: deviceHistorySlice,

})

export default reducers;