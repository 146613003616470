import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../../helpers/request";

interface ListType {
  count: string
  limit: number
  page: number
  results: any
}

export const _getPods = createAsyncThunk('pod/_list', async () => {
  const response: ListType = await request({ url: "pods", method: 'get' });
  return response;
});
